<template>
  <k-page>
    <h2 class="title font-weight-light my-4">Categorias</h2>

    <!-- Categorias nas telas pequenas -->
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col class="py-1" v-for="category in categories" :key="category.id">
        <v-card
          hover
          outlined
          flat
          :to="`/help/faqs/category/${category.id}`"
          class="pa-2 d-flex justify-cente flex-row align-center"
        >
          <v-icon color="primary" size="48" left>{{ category.icon }}</v-icon>
          <h2 class="subtitle font-weight-light">{{ category.name }}</h2>
          <!-- <p class="subtitle font-weight-light">{{category.description}}</p> -->
        </v-card>
      </v-col>
    </v-row>

    <!-- Categorias nas telas grandes -->
    <v-row v-else class="justify-center">
      <v-col md="6" lg="4" v-for="category in categories" :key="category.id">
        <v-card
          hover
          outlined
          :to="`/help/faqs/category/${category.id}`"
          min-height="200"
          class="
            pa-2
            d-flex
            justify-center
            flex-column
            align-center
            fill-height
          "
        >
          <v-icon color="primary" size="48">{{ category.icon }}</v-icon>
          <h2 class="display-1 font-weight-light text-center">
            {{ category.name }}
          </h2>
          <p class="subtitle font-weight-light text-center">
            {{ category.description }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <!-- Campo de busca -->
    <v-row>
      <v-col md="8" offset-md="2">
        <v-text-field
          @keypress.enter="search"
          v-model="query"
          @click:clear="clear"
          clearable
          outlined
          flat
          placeholder="Pesquisar"
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Resultados das busca -->
    <v-row v-if="results.length">
      <!--  -->
      <v-col md="8" offset-md="2">
        <h2 class="title font-weight-light mb-4">Resultados da Busca</h2>
        <FaqList :faqs="results" />

        <p class="caption grey--text">
          {{ results.length }} Resultados encontrados
        </p>
      </v-col>
    </v-row>

    <!--  -->
    <v-row v-if="!results.length">
      <!--  -->
      <v-col cols="12" md="6">
        <h2 class="title font-weight-light mb-4">Últimas Atualizações</h2>
        <FaqList :faqs="lastItems" />
      </v-col>

      <!--  -->
      <v-col cols="12" md="6">
        <h2 class="title font-weight-light mb-4">Melhores Avaliados</h2>
        <FaqList :faqs="bestRate" />
      </v-col>
    </v-row>
  </k-page>
</template>

<script>
// Internos
import FaqList from "./FaqList";
import KPage from "@/components/KPage";

export default {
  name: "FaqsIndex",
  components: {
    KPage,
    FaqList
  },

  data() {
    return {
      query: null,
      results: [],
      bestRate: [],
      lastItems: [],
      loading: false
    };
  },

  watch: {
    // query(newValue, oldValue) {
    //   this.search();
    // }
  },

  computed: {
    /**
     *
     */
    categories() {
      return this.$store.getters["faq_categories/all"];
    }
  },

  //
  methods: {
    async clear() {
      this.query = null;
      this.results = [];
    },

    /**
     * Faz uma busca
     */
    async search() {
      try {
        await this.$nextTick();
        this.$loading.show();
        const {
          data: { data }
        } = await this.$api.get("/faqs", {
          q: this.query
        });
        this.results = data;
        // console.log("resultados", this.results);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Pega as FAQ melhores avaliadas
     */
    async getBestRate() {
      try {
        const {
          data: { data }
        } = await this.$api.get("/faqs", {
          limit: 10,
          order: "rate",
          direction: "DESC"
        });
        // console.log("getbestrate", data);

        this.bestRate = data;
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    /**
     * Pega os últimos itens
     */
    async getLastItems() {
      try {
        const {
          data: { data }
        } = await this.$api.get("/faqs", {
          limit: 10,
          order: "faqs.updated_at",
          direction: "DESC"
        });
        // console.log("getLastItems", data);

        this.lastItems = data;
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();

        await Promise.all([
          this.$store.dispatch("faq_categories/all"),
          this.getBestRate(),
          this.getLastItems()
        ]);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$loading.hide();
      }
    }
  },

  async mounted() {
    await this.loadData();
  }
};
</script>
