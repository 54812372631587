<template>
  <v-container
    classs="col-12 col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
    v-cloak
  >
    <v-row>
      <v-col>
        <div>
          <v-breadcrumbs class="px-0" :items="breadcrumbs"></v-breadcrumbs>
        </div>

        <!-- Cabeçalho -->
        <h2 class="display-2 font-weight-light">{{ faq.title }}</h2>
        <div class="caption grey--text">
          <v-icon size="12">mdi-clock-outline</v-icon>
          Atualizado {{ faq.updated_at | timeAgo }}
        </div>

        <!-- Texto -->
        <markdown-view video :value="this.faq.text" />
      </v-col>
    </v-row>

    <!-- Avaliação -->
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <div>Este tópico foi útil?</div>

        <v-rating
          color="primary"
          background-color="grey"
          hover
          v-model="rating"
        />

        <div v-if="rating">{{ rating }} / 5</div>
      </v-col>
    </v-row>

    <!-- Texto de avaliação da pergunta -->
    <v-expand-transition>
      <v-row v-if="isRating">
        <v-col md="8" class="d-flex align-center justify-center">
          <v-text-field
            outlined
            solo
            flat
            hide-details
            v-model="text"
            placeholder="Nos diga o porque desta avaliação"
          ></v-text-field>
        </v-col>
        <v-col md="1" class="d-flex align-center justify-center">
          <v-btn text color="grey" @click="isRating = false">Cancelar</v-btn>
        </v-col>
        <v-col md="1" class="d-flex align-center justify-center">
          <v-btn text color="primary" @click="rate">Enviar</v-btn>
        </v-col>
      </v-row>
    </v-expand-transition>

    <!-- Título -->
    <v-row>
      <v-col class="d-flex align-center justify-start">
        <h2 class="font-weight-light">Não encontrou o que procura?</h2>
      </v-col>
    </v-row>

    <!-- Barra de busca -->
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <v-text-field
          @keypress.enter="search"
          v-model="query"
          outlined
          small
          flat
          placeholder="Pesquise perguntas frequentes"
          solo
          hide-details
          clearable
          @click:clear="clear"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Resultados da busca -->
    <v-expand-transition>
      <v-row v-if="results.length">
        <v-col class>
          <h2 class="font-weight-light">Resultados da Busca</h2>

          <FaqList :faqs="results" />

          <p class="caption grey--text">
            {{ results.length }} Resultados encontrados
          </p>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-container>
</template>

<script>
import FaqList from "./FaqList";
import MarkdownView from "@/components/Markdown/view";

export default {
  components: {
    FaqList,
    MarkdownView
  },

  data() {
    return {
      //
      rating: null,
      faq: {},
      text: null,
      isRating: false,
      results: [],
      query: null
    };
  },

  watch: {
    rating() {
      this.isRating = true;
    }
  },

  computed: {
    breadcrumbs() {
      return [
        // { to: "/", text: "Início", link: true },
        {
          to: "/help/faqs",
          exact: true,
          text: "Perguntas Frequentes",
          link: true
        },
        {
          to: `/help/faqs/category/${this.faq.category &&
            this.faq.category.id}`,
          text: this.faq.category && this.faq.category.name,
          link: true
        },
        {
          link: false,
          to: `/help/faqs/faq/${this.faq.id}`,
          text: this.faq.title
        }
      ];
    }
  },

  //
  methods: {
    /**
     *
     */
    async rate() {
      try {
        this.$loading.show();
        this.isRating = false;
        // console.log("rate");
        const payload = { text: this.text, rate: this.rating };
        await this.$store.dispatch("faqs/rate", {
          id: this.$route.params.id,
          payload
        });
        this.text = null;
        this.$loading.hide();
        this.$message.success("Voto adicionado com sucesso");
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Faz uma busca
     */
    async search() {
      try {
        await this.$nextTick();
        this.$loading.show();
        const {
          data: { data }
        } = await this.$api.get("/faqs", {
          q: this.query
        });
        this.results = data;
        // console.log("resultados", this.results);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    clear() {
      this.results = [];
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadData() {
      try {
        this.$loading.show();

        this.faq = await this.$store.dispatch("faqs/view", this.$route);
        this.rating = this.faq.rate;
        await this.$nextTick();
        this.isRating = false;

        this.$loading.hide();
      } catch (error) {
        console.log(error.response.status);
        if (error.response.status === 404) {
          this.$router.push("/error/404");
        }
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  },

  async beforeMount() {
    // this.$store.dispatch("faq_categories/refresh");
    await this.loadData();
  },
  mounted() {
    // this.addLinkEvent();
  }
};
</script>
