<template>
  <k-page>
    <div>
      <v-breadcrumbs class="px-0" :items="breadcrumbs"></v-breadcrumbs>
    </div>

    <v-row>
      <v-col>
        <v-card
          flat
          color="transparent"
          min-height="200"
          class="pa-2 d-flex justify-center flex-column align-center"
        >
          <v-icon color="primary" size="60">{{ category.icon }}</v-icon>
          <h2 class="display-1 font-weight-light">{{ category.name }}</h2>
          <p class="subtitle font-weight-light">
            {{ category.description }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <SectionHeader title="Resultados nesta categoria" />

    <v-row>
      <v-col>
        <FaqList :faqs="faqs" />
        <p class="caption grey--text">
          {{ faqs.length }} Resultados encontrados
        </p>
      </v-col>
    </v-row>
  </k-page>
</template>

<script>
import FaqList from "./FaqList";
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    KPage,
    FaqList,
    SectionHeader
  },

  data() {
    return {
      //
      faqs: []
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    faq_categories() {
      return this.$store.getters["faq_categories/all"];
    },
    /**
     *
     */
    category() {
      try {
        const category_id = this.$route.params.id;
        const cat = this.$store.getters["faq_categories/all"].find(
          item => item.id == category_id
        );

        if (cat) return cat;
        return {};
      } catch (error) {
        return {};
      }
    },
    breadcrumbs() {
      return [
        // { to: "/", text: "Início", link: true },
        {
          to: "/help/faqs",
          exact: true,
          text: "Perguntas Frequentes",
          link: true
        },
        {
          to: `/help/faqs/category/${this.category && this.category.id}`,
          text: this.category && this.category.name,
          link: true
        }
      ];
    }
  },

  //
  methods: {
    async getFaqsByCategory() {
      try {
        const {
          data: { data }
        } = await this.$api.get("/faqs", {
          category: this.$route.params.id
        });

        this.faqs = data;

        return data;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadData() {
      try {
        this.$loading.show();

        await Promise.all([
          this.getFaqsByCategory(),
          this.$store.dispatch("faq_categories/all")
        ]);

        this.$loading.hide();
      } catch (error) {
        // Se a categoria não for localizada
        if (error.response && error.response.status === 404)
          this.$router.push("/error/404");

        this.$loading.hide();
        this.$message.serverError(error);
      }
    }
  },

  async mounted() {
    await this.loadData();
  }
};
</script>
