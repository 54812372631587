<template>
  <v-card outlined>
    <v-list class="py-0 bordered">
      <v-list-item
        v-for="faq in faqs"
        :key="faq.id"
        :to="`/help/faqs/faq/${faq.id}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ faq.title }}
            <span class="d-inline caption grey--text">{{ faq.text }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon size="12" left>mdi-label</v-icon>
            {{ faq.category && faq.category.name }}
            <v-icon size="12" left>mdi-star</v-icon>
            <span v-if="faq.rate">{{ faq.rate }}/5</span>
            <span v-else>Não avaliado</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    faqs: Array
  }
};
</script>

<style>
</style>
